.coverImage {
    width: 100%;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
    outline: solid;
    outline-width: 2px;
}

.zineDesc {
    text-align: center;
    font-family: 'Roboto';
    font-size: 12px;
}

.zineTitle {
    text-align: center;
    font-family: 'Roboto';
    margin-bottom: -10px;
    font-size: 24px;
}

.coverLink:hover {
    opacity: .9;
}